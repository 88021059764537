/* eslint-disable */
import React from 'react';
import Layout from '../components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Experiencetab from '../components/Experiencetab';
import Gettouch from '../components/Gettouch';
import Headinfo from '../components/Headinfo';
import Meeting from '../components/Meeting';
import BuyVsBuildTop from '../components/buyvsbuildtop';
import { Helmet } from 'react-helmet';

import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const buyvsbuild = (props) => {
  const buyvsbuildImages = useStaticQuery(graphql`
    query buyvsbuildImages {
      louis: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/louis.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      jay: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/jay.png/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      anton: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/anton.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);
  return (
    <div className='experienceurbianpage pink'>
      <Layout>
        <Helmet>
          <title>Urbian | Buy vs build</title>
        </Helmet>
        <BuyVsBuildTop></BuyVsBuildTop>
        <Container className='spb-5 border-bottom'>
          <Row>
            <Col lg='6'>
              <div className='sp-5'>
                <h3>What is it?</h3>
                <p>
                  In this session we will go through this age old question and
                  find out what which portions of what you need done should
                  bought ‘off the shelf’ when that is going to become
                  problematic.
                </p>
                <p>
                  Meet a Product Owner and find out how you can increase the
                  speed of delivery by navigating what to build and what you
                  should buy.
                </p>
              </div>
              <div className='sp-5'>
                <h3>Who is it for?</h3>
                <p>
                  Product Owners, Product Managers, Innovation leaders and
                  business function leaders who want to create innovative,
                  user-centric digital products.
                </p>
              </div>
              <div className='sp-5'>
                <h3>What will you learn?</h3>
                <ul>
                  <li>
                    Discover how your current tech stack can better work
                    together to support your innovation efforts
                  </li>
                  <li>
                    Learn how and when to use API’s vs a service mesh to improve
                    existing experiences
                  </li>
                  <li>
                    Learn how to use evidence to remove blockers between
                    departments and stakeholders
                  </li>
                </ul>
              </div>
              <h3>Who will run it?</h3>
              <p>A Product Owner or Delivery Lead.</p>
              <div className='team-grid'>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={buyvsbuildImages.louis.childImageSharp.sizes}
                      alt='Louis Bester'
                    />
                  </div>
                  <h6>Louis Bester</h6>
                  <span>Product Owner</span>
                </div>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={buyvsbuildImages.jay.childImageSharp.sizes}
                      alt='Jay Thomson'
                    />
                  </div>
                  <h6>Jay Thomson</h6>
                  <span>Managing Partner</span>
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <Meeting location={props.location.pathname}></Meeting>
            </Col>
          </Row>
        </Container>
        <Headinfo></Headinfo>
        <Gettouch></Gettouch>
      </Layout>
    </div>
  );
};
export default buyvsbuild;
